let appUrl = 'atlas.onlygods.com';
let apiUrl = 'atlasapi.onlygods.com';

if (process.env.NODE_ENV === 'development') {
    appUrl = 'http://app.atlas.local:8080';
    apiUrl = 'http://api.atlas.local:80';
}

export const GeneralConfig: IConfigGeneral = {
    appUrl,
    apiUrl
};

export interface IConfigGeneral {
    appUrl: string;
    apiUrl: string;
};



