import { createApp } from 'vue';
import { store } from '@/store';

import router from './router'
import App from './App.vue';
import Config from '@/plugins/config';

createApp(App)
    .use(Config)
    .use(router)
    .use(store)
    .mount('#app');
