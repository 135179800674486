// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./assets/css/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{background:#1E1E20;color:#FFF;font-family:'ChakraPetch', 'sans-serif';font-weight:300;padding:0;margin:0}body{box-sizing:border-box;margin:0 0 20px;padding:0 20px}a{color:#FFF;text-decoration:none}ul{list-style-type:none;margin:0;padding:0}fieldset{border:none;outline:none}#app{margin:0 auto;max-width:1200px}\n", "",{"version":3,"sources":["webpack://./src/App.vue"],"names":[],"mappings":"AAIA,KACI,kBAAmB,CACnB,UAAW,CACX,uCAAwC,CACxC,eAAgB,CAChB,SAAU,CACV,QAAS,CACZ,KAGG,qBAAsB,CACtB,eAAgB,CAChB,cAAe,CAClB,EAGG,UAAW,CACX,oBAAqB,CACxB,GAGG,oBAAqB,CACrB,QAAS,CACT,SAAU,CACb,SAGG,WAAY,CACZ,YAAa,CAChB,KAGG,aAAc,CACd,gBAAiB","sourcesContent":["@import \"@/assets/css/variables.scss\";\n\n@import '@/assets/css/fonts.css';\n\nhtml {\n    background: #1E1E20;\n    color: #FFF;\n    font-family: 'ChakraPetch', 'sans-serif';\n    font-weight: 300;\n    padding: 0;\n    margin: 0;\n}\n\nbody {\n    box-sizing: border-box;\n    margin: 0 0 20px;\n    padding: 0 20px;\n}\n\na {\n    color: #FFF;\n    text-decoration: none;\n}\n\nul {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\nfieldset {\n    border: none;\n    outline: none;\n}\n\n#app {\n    margin: 0 auto;\n    max-width: 1200px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
