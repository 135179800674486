import { post, get } from '@/services/api';

function emptyF(responseData: Object) {}


function register(email: string, pw: string, sc = emptyF, fc = emptyF) {
    const data = {
        email,
        password: pw
    };

    return post('register', data, sc, fc);
}

function login(email: string, pw: string, sc = emptyF, fc = emptyF) {
    const data = {
        email,
        password: pw
    };

    return post('login', data, sc, fc);
}

function logout(sc = emptyF, fc = emptyF) {
    return get('logout', sc, fc);
}

function getCsrfToken() {
    get('csrf-token');
}


function verifyEmail(userId: number, token: string, sc = emptyF, fc = emptyF) {
    return get(`verify-email/${userId}/${token}`, sc, fc)
}


export {
    getCsrfToken,
    login,
    logout,
    register,
    verifyEmail,
}