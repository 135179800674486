<template>
    <header>
        <nav>
            <ul class="menu left">
                <li>Catalog</li>
                <li>Software Universe Map</li>
            </ul>

            <ul class="menu right">



                <li v-if="!isLoggedIn">
                    <router-link :to="{ name: 'login' }">Login</router-link>
                </li>
                <li v-if="!isLoggedIn">
                    <router-link :to="{ name: 'sign-up' }">Sign up</router-link>
                </li>

                <li v-if="isLoggedIn">
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                </li>

                <li v-if="isLoggedIn">
                    <span @click="onLogout">Logout</span>
                </li>
            </ul>
        </nav>

        <div class="lg-line lg-line-top"></div>

        <div id="logo-holder">


            <span class="lg-line lg-line-left"></span>
            <span class="lg-line lg-line-right"></span>

            <router-link to="/">
                <img id="logo" src="@/assets/img/atlas-logo.png" />
                <span class="logo-name">ATLAS</span>
            </router-link>

            <div class="mask"></div>
        </div>
    </header>
</template>

<script>
import { logout } from '@/services/auth';

export default {
    name: 'AppHeader',
    computed: {
        isLoggedIn() {
            return this.$store.state.isLoggedIn;
        }
    },
    methods: {
        onSuccessLogout() {
            this.$store.commit('setLoggedIn', false);
            this.$router.push({ name: 'home' });
        },
        onFailedLogout() {
            alert('Failed loging out. PLease try again');
        },

        onLogout() {
            logout(
                this.onSuccessLogout,
                this.onFailedLogout
            );
        }
    }
}
</script>
<style lang="scss">
$logoLineSideTransformOffset: 44px;
$logoOutlineSpacing: 20px;

/* 50% of actual logo width. */
$logoHolderWidth: 172px;

header {
    margin-bottom: 32px;
    margin-top: 60px;
    position: relative;
}
    nav {
        border-bottom: 1px solid $primaryBorderClr;
        color: #FFF;
        display: flex;
        font-weight: lighter;
        justify-content: space-between;
        position: absolute;

        /**
         * add the margin of the outlines
         * +1 for the height of the top outline.
         */
        top: 55px + $logoOutlineSpacing + 1px;
        width: 100%;

        .menu {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            display: inline;
            margin: 0 10px;
        }

        li:first-child {
            margin-left: 0;
        }

        li:last-child {
            margin-right: 0;
        }

        .menu.left {
            left: 0;
            text-align: left;
        }

        .menu.right {
            right: 0;

            text-align: right;
        }
    }

#logo-holder {
    margin: 0 auto;
    position: relative;
    width: 172px; /* 50% of full size. */
}
    .lg-line {
        background: rgba(255, 255, 255, .2);
        transition-property: left, top, right;
        transition-duration: .5s;
        z-index: 1;
    }

    .lg-line-top {
        height: 1px;
        left: 50%;
        margin: 0 auto $logoOutlineSpacing;
        width: $logoHolderWidth * 1.25;
    }

    .lg-line-left,
    .lg-line-right {
        position: absolute;
        height: 100%;
        top: 50%;
        transform-origin: center;

        width: 1px;
    }

    .lg-line-left {
        left: -$logoOutlineSpacing * 1.25;
        transform: translate($logoLineSideTransformOffset, -50%) rotate(-30.5deg);
    }

    .lg-line-right {
        right: -$logoOutlineSpacing * 1.25;
        transform: translate(-$logoLineSideTransformOffset, -50%) rotate(30.5deg);
    }

    #logo {
        position: relative;
        width: 172px;
        z-index: 2;
    }

    .logo-name {
        color: #FFF;
        font-size: 40px;
        font-weight: 600;
        left: 50%;
        position: absolute;
        top: 50%;
        text-shadow: 0 2px 5px #000;
        transform: translate(-50%, -45px);
        z-index: 3
    }

    .mask {
        background: #1E1E20;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%, 0);
        width: 157px;
        z-index: 0;
    }

</style>
