<template>
    <div id="banner">
        Pre-Alpha Version - Any feature/design is subject to change/removal without prior notice.
    </div>
</template>

<script>
export default {
    name: 'Banner',
    data: function() {
        return {
        }
    },
    components: {
    },
    methods: {
    },

}
</script>

<style lang="scss" scoped>
#banner {
    background: rgba(255, 0, 0, 0.1);
    padding: 7px 15px;
    text-align: center;
}
</style>
