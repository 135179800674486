<template>
    <div id="footer">

        <ul class="list">
            <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
            </li>
            <li>
                <router-link to="/cookie-policy">Cookie Policy</router-link>
            </li>
            <li>
                <router-link to="/terms">Terms</router-link>
            </li>
        </ul>

        <span>~ ask not for a lighter burden, but for broader shoulders</span>
    </div>
</template>
<script>
export default {
    name: 'AppFooter'
}
</script>
<style lang="scss" scoped>
#footer {
    border-top: 1px solid $primaryBorderClr;
    display: flex;
    font-size: 14px;
    font-weight: 300;
    justify-content: space-between;
    padding-top: 10px;
}

.list {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
    }

    li:after {
        content: '|';
        margin: 0 10px;
    }

    li:last-child:after {
        opacity: 0;
    }
}


</style>
