import { App, Plugin } from 'vue'
import { Config } from '@/config';

const ConfigPlugin: Plugin = {
    install(app: App, options?) {
        app.config.globalProperties.$config = Config;
    }
}

export default ConfigPlugin;
