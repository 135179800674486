import { store } from '@/store';
import { RouteLocationNormalized } from 'vue-router';

type guard = (to: RouteLocationNormalized) => boolean;

const isAuthenticated: guard = function(to) {
    if (store.state.isLoggedIn === true) {
        return true;
    }

    return false;
}

export {
    guard,
    isAuthenticated
}
