// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/ChakraPetch-Bold.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/ChakraPetch-SemiBold.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../fonts/ChakraPetch-Medium.ttf";
import ___CSS_LOADER_URL_IMPORT_3___ from "../fonts/ChakraPetch-Light.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'ChakraPetch';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    font-style: normal;\n    font-weight: 700;\n}\n\n\n@font-face {\n    font-family: 'ChakraPetch';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    font-style: normal;\n    font-weight: 600;\n}\n\n@font-face {\n    font-family: 'ChakraPetch';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    font-style: normal;\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: 'ChakraPetch';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    font-style: normal;\n    font-weight: 300;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4CAAyC;IACzC,kBAAkB;IAClB,gBAAgB;AACpB;;;AAGA;IACI,0BAA0B;IAC1B,4CAA6C;IAC7C,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,4CAA2C;IAC3C,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,4CAA0C;IAC1C,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: 'ChakraPetch';\n    src: url('../fonts/ChakraPetch-Bold.ttf');\n    font-style: normal;\n    font-weight: 700;\n}\n\n\n@font-face {\n    font-family: 'ChakraPetch';\n    src: url('../fonts/ChakraPetch-SemiBold.ttf');\n    font-style: normal;\n    font-weight: 600;\n}\n\n@font-face {\n    font-family: 'ChakraPetch';\n    src: url('../fonts/ChakraPetch-Medium.ttf');\n    font-style: normal;\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: 'ChakraPetch';\n    src: url('../fonts/ChakraPetch-Light.ttf');\n    font-style: normal;\n    font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
