<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
import DefaultLayout from '@/layouts/Default';
import { markRaw } from 'vue';
import { getCsrfToken } from '@/services/auth';

export default {
    name: 'App',
    data: function() {
        return {
            layout: markRaw(DefaultLayout)
        }
    },
    watch: {
        $route: {
            immediate: true,
            async handler(route) {
                try {
                    const component = await import(`@/layouts/${route.meta.layout}.vue`)
                    const layout = component?.default || DefaultLayout;

                    this.layout = markRaw(layout);
                } catch (e) {
                    this.layout = markRaw(DefaultLayout);
                }
            }
        }
    },
    mounted: function() {
        getCsrfToken();

        const val = window.localStorage.getItem('isLoggedIn');
        this.$store.state.isLoggedIn = (val === 'true');
    }
}
</script>

<style lang="scss">
@import '@/assets/css/fonts.css';

html {
    background: #1E1E20;
    color: #FFF;
    font-family: 'ChakraPetch', 'sans-serif';
    font-weight: 300;
    padding: 0;
    margin: 0;
}

body {
    box-sizing: border-box;
    margin: 0 0 20px;
    padding: 0 20px;
}

a {
    color: #FFF;
    text-decoration: none;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

fieldset {
    border: none;
    outline: none;
}

#app {
    margin: 0 auto;
    max-width: 1200px;
}

</style>
