import { createStore } from 'vuex'

const store = createStore({
    state: {
        isLoggedIn: false,
    },

    getters: {

    },

    mutations: {
        setLoggedIn(state, payload: boolean) {
            state.isLoggedIn = payload;
            window.localStorage.setItem('isLoggedIn', String(payload));
        }
    },

    actions: {
    },

    modules: {
    }
});

export {
    store
};
