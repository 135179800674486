import { metaProperty } from '@babel/types';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { guard, isAuthenticated } from './guards';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/about.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile.vue'),
        meta: {
            layout: 'Default',
            guards: [
                isAuthenticated
            ]
        }
    },

    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import(/* webpackChunkName: "sign-up" */ '@/views/sign-up.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/verify-email/:userId/:token',
        name: 'verify-email',
        component: () => import(/* webpackChunkName: "verify-email" */ '@/views/verify-email.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/team',
        name: 'team',
        component: () => import(/* webpackChunkName: "team" */ '@/views/team.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/big-o',
        name: 'big-o',
        component: () => import(/* webpackChunkName: "big-o" */ '@/views/courseoverview/big-o.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/javascript',
        name: 'javascript',
        component: () => import(/* webpackChunkName: "javascript" */ '@/views/courseoverview/javascript.vue'),
        meta: {
            layout: 'Default'
        }
    },

    /**
     *
     */

    {
        path: '/pricing',
        name: 'pricing',
        component: () => import(/* webpackChunkName: "pricing" */ '@/views/pricing.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/enterprise',
        name: 'enterprise',
        component: () => import(/* webpackChunkName: "enterprise" */ '@/views/enterprise.vue'),
        meta: {
            layout: 'Default'
        }
    },

    /**
     * Support.
     */
    {
        path: '/help-center',
        name: 'help-center',
        component: () => import(/* webpackChunkName: "help-center" */ '@/views/support/help-center.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/support/contact.vue'),
        meta: {
            layout: 'Default'
        }
    },

    /**
     * Legal.
     */
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/legal/privacy-policy.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/cookie-policy',
        name: 'cookie-policy',
        component: () => import(/* webpackChunkName: "cookie-policy" */ '@/views/legal/cookie-policy.vue'),
        meta: {
            layout: 'Default'
        }
    },

    {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "terms" */ '@/views/legal/terms.vue'),
        meta: {
            layout: 'Default'
        }
    },

    /**
     * Catch all.
     */
    {
        path: '/:pathMatch(.*)*',
        name: '404',

        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
        meta: {
            layout: 'Default'
        }
    }
]

const router = createRouter({
    history: createWebHistory('/'),
    // history: createWebHistory(process.env.BASE_URL),
    routes
});


router.beforeResolve(async to => {
    // Check all guards.
    if (to.meta.guards) {
        const guards = to.meta.guards as Array<guard>;

        for (let i = 0; i < guards.length; i++) {
            const guard = guards[i];

            const res = guard(to);

            // If any guard fails redirect to homepage.
            if (res === false) {
                router.push({ name: 'home' })
            }
        }
    }
});

export default router
