import { GeneralConfig, IConfigGeneral } from './general';

export const Config: IConfigOptions = {
    general: GeneralConfig
};

export interface IConfigOptions {
    general: IConfigGeneral;
}

