<template>
    <Banner></Banner>
    <Header></Header>
    <slot />
    <PreFooter></PreFooter>
    <Footer></Footer>
</template>

<script>
import Banner from '@/layouts/components/banners/Banner';
import Header from '@/layouts/components/Header';
import Footer from '@/layouts/components/Footer';
import PreFooter from '@/layouts/components/PreFooter';

export default {
    name: 'Default',
    components: {
        Banner,
        Header,
        Footer,
        PreFooter
    }
}
</script>

