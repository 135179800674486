import axios from 'axios';
import { Config } from '@/config';

function emptyCallback(responseData: Object) {}


const axiosInstance = axios.create({
    baseURL: Config.general.apiUrl + '/v1/',

    // Activate sending XSRF tokens.
    withCredentials: true,

    // See laravel backend (VerifyCsrfToken.php)
    xsrfHeaderName: 'X-XSRF-TOKEN',
    xsrfCookieName: 'XSRF-TOKEN'
});


// Todo: Create some kind of class
// that has configuration so I can pass it
// for each specific api call and decide how errors
// should be handled.
axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        // See https://axios-http.com/docs/handling_errors.
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);

            // TODO: Flag any 404 for critical status for inspection.
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log('no response', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

        return Promise.reject(error);
    }
);

const post = (url: string, data: Object, success = emptyCallback, fail = emptyCallback) => {
    return axiosInstance.post(url, data)
        .then(response => {
            success(response.data);
        })
        .catch((error) => {
            fail(error);
        });
}

const get = (url: string, success = emptyCallback, fail = emptyCallback) => {
    return axiosInstance.get(url)
        .then(response => {
            success(response.data);
        })
        .catch(error => {
            fail(error);
        });
}

export {
    axiosInstance,
    get,
    post,
};
