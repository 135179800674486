<template>
    <div id="prefooter">

        <div class="column left">
            <div>
                <h2>COMPANY</h2>

                <ul>
                    <li>
                        <router-link :to="{ name: 'about' }">About</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'team' }">Team</router-link>
                    </li>
                </ul>
            </div>

            <div>
                <h2>PRO PLANS</h2>
                <ul>
                    <li>
                        <router-link :to="{ name: 'pricing' }">Individual</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'pricing' }">Student</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'enterprise' }">Enterprise</router-link>
                    </li>
                </ul>
            </div>

            <div>
                <h2>Support</h2>

                <ul>
                    <li>
                        <router-link :to="{ name: 'help-center' }">Help Center</router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: 'contact' }">Contact</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="column right">

        </div>
    </div>
</template>
<script>
export default {
    name: 'PreFooter'
}
</script>
<style lang="scss" scoped>
#prefooter {
    border-top: 1px solid $primaryBorderClr;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    padding: 32px 0;

}


h2 {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.column {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.column.left {
    border-right: 1px solid $primaryBorderClr;
    padding-right: 50px;
}

.column.right {
    padding-left: 50px;
}

</style>